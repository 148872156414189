<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Route Information
      <b-button slot="right" type="is-primary" @click="assignRoute">
        Assign Route
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <b-progress v-if="isLoading" size="is-large" show-value>
        Fetching Route Information
      </b-progress>
      <b-tabs type="is-boxed" v-model="openedTab" v-else>
        <b-tab-item label="Details" icon="playlist-edit" class="is-success">
          <form @submit.prevent="submit">
              <div class="columns">
                <div class="column">
                  <b-field label="Route Name" expanded>
                    <b-input
                      minlength="3"
                      v-model="route.name"
                      name="routeName"
                      required
                    />
                  </b-field>
                </div>
              </div>
              <hr />
              <b-table
                v-if="customers.length"
                :data="customers"
                hoverable
                draggable
                @dragstart="dragstart"
                @drop="drop"
                @dragover="dragover"
                @dragleave="dragleave"
              >
                <b-table-column field="name" label="Customer Name" v-slot="props">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="address" label="Address" v-slot="props">
                    {{ props.row.address }}
                </b-table-column>

                <b-table-column field="villageCode" label="Village" v-slot="props">
                    {{ customerVillagesMapping[props.row.villageCode] || props.row.villageCode}}
                </b-table-column>

                <b-table-column field="statusCode" label="Status Code" centered v-slot="props">
                    {{ customerStatusesMapping[props.row.statusCode] || props.row.statusCode }}
                </b-table-column>

                <b-table-column field="numberOfBins" label="Count of bin/s" centered v-slot="props">
                    {{ props.row.bins.length }}
                </b-table-column>

                <b-table-column field="action" label="Action" v-slot="props" centered>
                  <b-button type="is-danger" size="is-small" @click="remove(props.index)">Remove</b-button>
                </b-table-column>
              </b-table>
              <div class="content has-text-grey has-text-centered" v-else>
                <p>
                  <b-icon icon="playlist-remove" size="is-large" />
                </p>
                <p>No customers. Click Add Customers to add.</p>
              </div>
              <hr />
              <b-field grouped position="is-right">
                <div class="control">
                  <b-button @click="exit"
                    >Cancel</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" @click="addCustomers"
                    >Add Customers</b-button
                  >
                </div>
                <div class="control">
                  <b-button native-type="submit" type="is-primary" :disabled="!infoHasChanges"
                    >Update</b-button
                  >
                </div>
              </b-field>
          </form>
        </b-tab-item>
        <b-tab-item label="Records" icon="format-list-bulleted">
          <assigned-route-list :label="this.oldRouteInfo.name" mode="route" :key="assignedListKey" ref="assignedRouteList"/>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import route from '@/services/route'
import assignRoute from '@/services/assign_route'
import { mapGetters } from 'vuex'

import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import AssignedRouteList from '@/components/AssignedRouteList'
import AddRouteCustomers from '@/modals/AddRouteCustomers'
import AssignRoute from '@/modals/AssignRoute'

export default {
  name: 'RouteInfo',
  components: {
    TitleBar,
    HeroBar,
    AssignedRouteList
  },
  data () {
    return {
      openedTab: 0,
      assignedListKey: 1,
      id: null,
      isLoading: false,
      oldRouteInfo: {},
      route: {},
      customers: [],
      orderChanged: false
    }
  },
  async mounted () {
    this.id = this.$route.params.id
    this.isLoading = true
    await this.loadRouteInfo(this.id)
    this.isLoading = false
  },
  methods: {
    submit () {
      this.updateRoute()
    },
    async loadRouteInfo () {
      try {
        const routeInfo = await route.getRouteById(this.id)
        this.oldRouteInfo = { ...routeInfo }
        this.route = { ...routeInfo }
        this.customers = [...routeInfo.customers.sort((a, b) => a.sequence - b.sequence)]
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$router.push({ path: '/routes' })
      }
    },
    async updateRoute () {
      try {
        if (this.route.name !== this.oldRouteInfo.name) {
          await route.updateRouteById(this.id, { name: this.route.name })
        }

        if (this.orderChanged) {
          await route.updateCustomersToRoute(this.id, {
            customerIds: this.customers.map(({ id }) => id)
          })
          this.orderChanged = false
        }

        this.$buefy.snackbar.open({
          message: 'Route updated successfully',
          queue: false
        })
        this.oldRouteInfo.name = this.route.name
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    addCustomers () {
      this.$buefy.modal.open({
        component: AddRouteCustomers,
        parent: this,
        props: {
          routeId: parseInt(this.id)
        },
        events: {
          onCustomersAdd: async ({ customers, close }) => {
            try {
              const temp = [...this.customers, ...customers]
              await route.updateCustomersToRoute(this.id, {
                customerIds: temp.map(({ id }) => id)
              })
              this.customers = temp
              this.$buefy.toast.open('Customers added successfully')
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    remove (idx) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove customer?',
        onConfirm: async () => {
          try {
            await route.removeCustomerToRoute(this.id, this.customers[idx].id)
            const tempCopy = [...this.customers]
            tempCopy.splice(idx, 1)
            this.customers = tempCopy
            this.$buefy.toast.open('Customer removed successfully')
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    exit () {
      // check if there is data added
      if (this.infoHasChanges) {
        this.$buefy.dialog.confirm({
          message: 'Information will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    },
    assignRoute () {
      this.$buefy.modal.open({
        component: AssignRoute,
        parent: this,
        props: {
          routeId: parseInt(this.id)
        },
        events: {
          onAssign: async (returnData) => {
            try {
              const result = await assignRoute.assignRouteToDriver(returnData.data)
              returnData.close()
              this.$buefy.snackbar.open({
                message: 'Route assigned successfully',
                queue: false
              })
              this.openedTab = 1
              this.assignedListKey += 1
              this.$refs.assignedRouteList.viewAssigned(result.assignedRoute.id)
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      const droppedOnRowIndex = payload.index

      if (droppedOnRowIndex === this.draggingRowIndex) return

      payload.event.target.closest('tr').classList.remove('is-selected')
      const temp = [...this.customers]
      // remove it first from the index;
      temp.splice(this.draggingRowIndex, 1)
      temp.splice(droppedOnRowIndex, 0, this.customers[this.draggingRowIndex])
      this.customers = temp
      this.orderChanged = true

      this.$buefy.toast.open(`Moved ${this.draggingRow.name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'customerStatusesMapping',
      'customerVillagesMapping'
    ]),
    infoHasChanges () {
      return this.route.name !== this.oldRouteInfo.name || this.orderChanged
    },
    titleStack () {
      return ['Route', 'View']
    }
  }
}
</script>
